<template>
  <div class="container">
    <ActionsMenu :isInValid="checkValid()" :inValidValue="'strCancelChanges'" />
    <div class="row">
      <div class="col-12 offer__header">
        <div class="offer__header-title">
          {{ $t("strOfferNe") }}
        </div>
      </div>
      <div class="col-12 offer__content">
        <form @submit.prevent="saveForm">
          <div class="row">
            <div class="col-lg-10">
              <div class="col-lg-3 col-md-6">
                <label class="offer--input-label" for="name">{{
                  $t("strOfferName")
                }}</label>
                <InputText
                  id="name"
                  class="offer--input"
                  type="text"
                  v-model="offer.name"
                  :class="{ 'is-invalid': submitted && $v.offer.name.$error }"
                  maxLength="40"
                />
                <div
                  v-if="submitted && $v.offer.name.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.offer.name.required">{{
                    $t("strOfferNameRequired")
                  }}</span>
                  <span v-if="!$v.offer.name.minLength">{{
                    $t("strOfferNameMinLength")
                  }}</span>
                  <span v-if="!$v.offer.name.minLength">{{
                    $t("strOfferNameValid")
                  }}</span>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="d-flex" @click="toggleCustomerValid">
                  <div style="width:70%">
                    <label class="offer--input-label" for="inputtext5"
                      >Klient</label
                    >
                    <Dropdown
                      id="customers"
                      class="offer--input"
                      :class="{
                        'is-invalid': submitted && $v.offer.idCustomer.$error,
                      }"
                      :options="customers"
                      optionLabel="alias"
                      optionValue="id"
                      style="padding: .8rem 0 .8rem 0;"
                      :filter="true"
                      :filterPlaceholder="$t('strSearch')"
                      :placeholder="$t('strCustomerSelect')"
                      :showClear="true"
                    />
                    <div
                      v-if="submitted && $v.offer.idCustomer.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.offer.idCustomer.required">{{
                        $t("strCustomerRequired")
                      }}</span>
                      <span v-if="!$v.offer.idCustomer.between">{{
                        $t("strCustomerRequired")
                      }}</span>
                    </div>
                  </div>
                  <div class="d-flex ml-2">
                    <VButton
                      type="button"
                      class="btn-outline-red"
                      style="height:36px;min-width:70px;margin-top:2.5rem"
                      @click="showAddNewCustomer"
                      icon="+"
                      strKey="strAdd"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 offer-footer toRight d-flex mt-xs-6">
              <VButton
                class="btn-outline-red mr-2"
                type="button"
                @click="closePage()"
                strKey="strCancel"
              />
              <VButton class="btn-red" type="submit" strKey="strOfferSave" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <Dialog
      header="Nowy klient"
      widgetVar="process_msg"
      id="process_msg"
      :visible.sync="displayNewCustomer"
      :style="{ width: '70%' }"
      :modal="true"
      @show="newCustomerOpened"
    >
      <customer-edit
        ref="customerEdit"
        :closeAddNewCustomer="closeAddNewCustomer"
      ></customer-edit>
    </Dialog>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDialogCancel"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="display: flex;padding: 5rem; justify-content: center; font-size: 20px;"
      >
        {{ $t("strCancelChanges") }}
      </div>
      <div
        style="display: flex;justify-content: space-between;padding: 0 2rem 1rem 2rem;"
      >
        <VButton
          class="btn btn-red"
          @click="displayDialogCancel = false"
          strKey="strCancel"
        />
        <VButton class="btn btn-outline-red" @click="exit()" strKey="strExit" />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { customerService } from "../../services/customer.service.js";

import ActionsMenu from "@/components/ActionsMenu";
import CustomerEdit from "@/components/customers/CustomerEdit";
import { orderService } from "../../services/order.service.js";

import {
  required,
  minLength,
  between,
  maxLength,
} from "vuelidate/lib/validators";
import VButton from "../global/VButton.vue";

export default {
  name: "OfferNew",
  data() {
    return {
      submitted: false,
      offer: {
        "id": 0,
    "firstName": "",
    "secondName": "",
    "alias": "",
    "name": "",
    "nip": "",
    "street": "",
    "place": "",
    "zipCode": "",
    "country": "",
    "idCountry": 0,
    "phone1": "",
    "phone2": "",
    "email": "",
    "notes": "",
    "rodo": 1
      },
      displayNewCustomer: false,
      displayDialogCancel: false,
      customers: [],
      customerInValid: false,
      pageTitle: "",
    };
  },
  validations: {
    offer: {
      name: { required, minLength: minLength(5), maxLength: maxLength(40) },
      idCustomer: { required, between: between(1, 1000000) },
    },
  },
  mounted: function mounted() {
    this.loadCustomers();
    // let t = this.$t('strOfferNe');
    // this.pageTitle = t === 'strOfferNe' ? 'Nowa oferta' : t;
  },
  methods: {
    saveForm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.saveOrder();
    },
    saveOrder() {
      orderService
        .saveNewOrder(this.offer.name, this.offer.idCustomer)
        .then((response) => {
          this.$router.push({
            name: "OfferEdit",
            params: { offerId: response.data.id },
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: this.$t("erOfferNewSaveError"),
          });
        });
    },
    loadCustomers: function() {
      var thisVue = this;
      customerService.getCustomers().then((response) => {
        console.log(response)
        //thisVue.customers = response.data.filter((i) => i.alias !== null);
        thisVue.customers = [ {
    "id": 672,
    "firstName": "Heiko",
    "secondName": "Meyer",
    "alias": "Heiko Meyer Meyer-Oyten DE (heiko.meyer@vetrex.eu)",
    "name": "Meyer-Oyten DE",
    "nip": "DE296569789",
    "street": "Bergstraße 86",
    "place": "Oyten",
    "zipCode": "28876",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "015150310997",
    "phone2": "",
    "email": "heiko.meyer@vetrex.eu",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 719,
    "firstName": "Herr Torsten ",
    "secondName": "Benfer",
    "alias": "Herr Torsten  Benfer FSS Fenster- und Sonnenschutz-Systeme (fss-benfer@web.de)",
    "name": "FSS Fenster- und Sonnenschutz-Systeme",
    "nip": "DE 198 943 112",
    "street": "Heide 45",
    "place": "Ennepetal",
    "zipCode": "58256",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 2333973633",
    "phone2": "",
    "email": "fss-benfer@web.de",
    "notes": "FSS-ENNEPETAL-DE",
    "rodo": 1
  },
  {
    "id": 724,
    "firstName": "Philip",
    "secondName": "Warnke",
    "alias": "Philip Warnke Warnke Holzbau GmbH (info@warnke-holzbau.de)",
    "name": "Warnke Holzbau GmbH",
    "nip": "DE310305720",
    "street": "Zur Tiefen Wiese 1",
    "place": "Oyten",
    "zipCode": "28876",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "01734466673",
    "phone2": "",
    "email": "info@warnke-holzbau.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 726,
    "firstName": "Volker",
    "secondName": "Lederer ",
    "alias": "Volker Lederer  Kerschberger Fensterbau UG (info@kerschberger-fensterbau.de)",
    "name": "Kerschberger Fensterbau UG",
    "nip": "DE301304936",
    "street": "Hauptstraße 45",
    "place": "Neunburg vorm Wald",
    "zipCode": "92431 ",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 96729275219",
    "phone2": "",
    "email": "info@kerschberger-fensterbau.de",
    "notes": "KF-NEUNBURG-DE\nFrau Eigenstetter",
    "rodo": 1
  },
  {
    "id": 729,
    "firstName": "Herr Ralf ",
    "secondName": "Harting",
    "alias": "Herr Ralf  Harting Wolfgang Harting GmbH (info@gm-harting.de)",
    "name": "Wolfgang Harting GmbH",
    "nip": "DE 812158880",
    "street": "Am Bahnhof 5",
    "place": "Bückeburg",
    "zipCode": "31675",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 57225458",
    "phone2": "",
    "email": "info@gm-harting.de",
    "notes": "HARTING-BUECKEBURG-DE",
    "rodo": 1
  },
  {
    "id": 733,
    "firstName": "Herr Markus ",
    "secondName": "Dickelmann",
    "alias": "Herr Markus  Dickelmann MDH VERTRIEBS KG (mdi@mdh-vertrieb.de)",
    "name": "MDH VERTRIEBS KG",
    "nip": "DE240548568",
    "street": "Blucherstr. 6",
    "place": "Lübeck",
    "zipCode": "23564",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 45131780000",
    "phone2": "+49 1722960006",
    "email": "mdi@mdh-vertrieb.de",
    "notes": "MDH-LUEBECK-DE\n48 - 30000 - 30 TAGE NETTO",
    "rodo": 1
  },
  {
    "id": 749,
    "firstName": "Dennis",
    "secondName": "Bräuler",
    "alias": "Dennis Bräuler Ecowindow GmbH (Kontakt@ecowindow.de)",
    "name": "Ecowindow GmbH",
    "nip": "DE300521352",
    "street": "Landsberger Allee 24",
    "place": "Berlin",
    "zipCode": "10249",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "01778884041",
    "phone2": "",
    "email": "Kontakt@ecowindow.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 751,
    "firstName": "Monika",
    "secondName": "Peters",
    "alias": "Monika Peters  (M.Jaworska@gmx.de)",
    "name": "",
    "nip": "",
    "street": "Am Knüll 5",
    "place": "Wasbek ",
    "zipCode": "24647",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "015170042256",
    "phone2": "",
    "email": "M.Jaworska@gmx.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 783,
    "firstName": "Werner",
    "secondName": "Zach",
    "alias": "Werner Zach Werner Zach GmbH Bauelemente & Möbeldesign (zach_werner@t-online.de)",
    "name": "Werner Zach GmbH Bauelemente & Möbeldesign",
    "nip": "DE195231000",
    "street": "Bemmerlmühle 1",
    "place": "Stadlern",
    "zipCode": "92549",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "096741235",
    "phone2": "",
    "email": "zach_werner@t-online.de",
    "notes": "ZACH-STADLERN-DE",
    "rodo": 1
  },
  {
    "id": 794,
    "firstName": "Michael",
    "secondName": "Nefzi",
    "alias": "Michael Nefzi Elbe Weser Projektentwicklung GmbH (mail@elbeweserbau.de)",
    "name": "Elbe Weser Projektentwicklung GmbH",
    "nip": "DE357079495",
    "street": "Rigaer Straße 1",
    "place": "Bremen",
    "zipCode": "28217",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "01739856643",
    "phone2": "",
    "email": "mail@elbeweserbau.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 911,
    "firstName": "Teddy",
    "secondName": "Ozokwere",
    "alias": "Teddy Ozokwere Ozokwere Transporte GmbH (Info@ozokwere.de)",
    "name": "Ozokwere Transporte GmbH",
    "nip": "DE355890151",
    "street": "Ostermannsfeld 24",
    "place": "Georgsmarienhütte",
    "zipCode": "49124",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "05401832137",
    "phone2": "01779361046",
    "email": "Info@ozokwere.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 945,
    "firstName": "Jörg",
    "secondName": "Hoppenau",
    "alias": "Jörg Hoppenau J.N. Köbig GmbH (joerg.hoppenau@koebig.de)",
    "name": "J.N. Köbig GmbH",
    "nip": "DE149054826",
    "street": "Bubenheimer Bann 9",
    "place": "Koblenz",
    "zipCode": "56070",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "00492619816945",
    "phone2": "",
    "email": "joerg.hoppenau@koebig.de",
    "notes": "Rechnungen an:\nJ.N. Köbig GmbH\nRheinallee 161-169\n55120 Mainz",
    "rodo": 1
  },
  {
    "id": 946,
    "firstName": "Konrad",
    "secondName": "Kleber",
    "alias": "Konrad Kleber Holzmontagebau Konrad Kleber (kleberkonrad@web.de)",
    "name": "Holzmontagebau Konrad Kleber",
    "nip": "23% MwSt",
    "street": "Brennerweiherstraße 1",
    "place": "Eslarn",
    "zipCode": "92693",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 9653912097",
    "phone2": "+49 1714898054",
    "email": "kleberkonrad@web.de",
    "notes": "KLEBER-ESLARN-DE",
    "rodo": 1
  },
  {
    "id": 967,
    "firstName": "Martin ",
    "secondName": "Maßloh",
    "alias": "Martin  Maßloh Bauelemente Martin Maßloh (m.massloh@web.de)",
    "name": "Bauelemente Martin Maßloh",
    "nip": "324007515",
    "street": "Feldstraße 13",
    "place": "Saarwellingen",
    "zipCode": "66793",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 171 4130692",
    "phone2": "",
    "email": "m.massloh@web.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 984,
    "firstName": "Waldemar",
    "secondName": "Krieger",
    "alias": "Waldemar Krieger Krieger Bauelemente (info@krieger-bauelemente.de)",
    "name": "Krieger Bauelemente",
    "nip": "DE300829476",
    "street": "Eickener Str. 32",
    "place": "Hattingen",
    "zipCode": "45525",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "00492324683075",
    "phone2": "",
    "email": "info@krieger-bauelemente.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 985,
    "firstName": "Alexander",
    "secondName": "Reks",
    "alias": "Alexander Reks Tischlerei Reks & Frackowiak GbR (info@tischlerei-rf.de)",
    "name": "Tischlerei Reks & Frackowiak GbR",
    "nip": "DE318565774",
    "street": "Langenbeckstraße 17",
    "place": "Hagen",
    "zipCode": "58097",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "004922313778904",
    "phone2": "",
    "email": "info@tischlerei-rf.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1018,
    "firstName": "Anton",
    "secondName": "Feiersinger",
    "alias": "Anton Feiersinger Innen -& Aussen Anton Feiersinger Schreinerei (feiersinger@t-online.de)",
    "name": "Innen -& Aussen Anton Feiersinger Schreinerei",
    "nip": "DE192114950",
    "street": "Unter der Burghalde 2b",
    "place": "Haldenwang",
    "zipCode": "87490",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "491717388893",
    "phone2": "",
    "email": "feiersinger@t-online.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1046,
    "firstName": "Ali-Riza",
    "secondName": "Top",
    "alias": "Ali-Riza Top A&P Fenster und Türen UG (info@fensterinhamm.de)",
    "name": "A&P Fenster und Türen UG",
    "nip": "DE123456789",
    "street": "Schwarzes Gold 7",
    "place": "Hamm",
    "zipCode": "59075",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "0023819950820",
    "phone2": "",
    "email": "info@fensterinhamm.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1068,
    "firstName": "GVG Connaught ",
    "secondName": "Verwaltungsgesellschaft mbH",
    "alias": "GVG Connaught  Verwaltungsgesellschaft mbH GVG (info@connaught-capital.com)",
    "name": "GVG",
    "nip": "DE320518387",
    "street": "Borselstraße 16A",
    "place": "Hamburg",
    "zipCode": "22765",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "04030008820",
    "phone2": "",
    "email": "info@connaught-capital.com",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1095,
    "firstName": "Peter",
    "secondName": "Grabowy",
    "alias": "Peter Grabowy Peter Grabowy GmbH (info@grabowy-fenster.de)",
    "name": "Peter Grabowy GmbH",
    "nip": "DE123456789",
    "street": "Beller Straße 6",
    "place": "Hürth",
    "zipCode": "50354",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "00492233691730",
    "phone2": "",
    "email": "info@grabowy-fenster.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1100,
    "firstName": "Benjamin",
    "secondName": "Münstermann",
    "alias": "Benjamin Münstermann Fenstertechnik Münstermann (info@fenstertechnik-muenstermann.de)",
    "name": "Fenstertechnik Münstermann",
    "nip": "DE303969584",
    "street": "Grasstraße 3",
    "place": "Essen",
    "zipCode": "45356",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "00492016474480",
    "phone2": "",
    "email": "info@fenstertechnik-muenstermann.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1158,
    "firstName": "Carsten ",
    "secondName": "Wegge",
    "alias": "Carsten  Wegge  (info@kontec-bau.de)",
    "name": "",
    "nip": "",
    "street": "Ginnheimer Landstraße 19",
    "place": "Frankfurt",
    "zipCode": "60487 ",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 171 7779064",
    "phone2": "",
    "email": "info@kontec-bau.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1160,
    "firstName": "Adam",
    "secondName": "Stein",
    "alias": "Adam Stein Adam Stein (A.Stein.vetrex@googlemail.com)",
    "name": "Adam Stein",
    "nip": "DE123456789",
    "street": "Elisabeth-Kadow-Str. 12",
    "place": "Neuss",
    "zipCode": "41470",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "015782421170",
    "phone2": "",
    "email": "A.Stein.vetrex@googlemail.com",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1187,
    "firstName": "Tobias",
    "secondName": "Geier",
    "alias": "Tobias Geier Holzmanufaktur Geier (info@holzmanufaktur-geier.de)",
    "name": "Holzmanufaktur Geier",
    "nip": "DE123456789",
    "street": "Corneliastr. 14",
    "place": "Essen",
    "zipCode": "45130",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "017621951773",
    "phone2": "",
    "email": "info@holzmanufaktur-geier.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1199,
    "firstName": "Anita",
    "secondName": "Mielke",
    "alias": "Anita Mielke M & S Solis (anita.mielke@gmx.de)",
    "name": "M & S Solis",
    "nip": "DE178256582",
    "street": "Boschstraße 1",
    "place": "Neuenkirchen",
    "zipCode": "29643",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "01732829019",
    "phone2": "",
    "email": "anita.mielke@gmx.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1207,
    "firstName": "Philipp",
    "secondName": "Schrimb",
    "alias": "Philipp Schrimb J.N.Köbig GmbH (Philipp.Schrimb@koebig.de)",
    "name": "J.N.Köbig GmbH",
    "nip": "DE 149 054 826",
    "street": "Rheinallee 161-166",
    "place": "Mainz",
    "zipCode": "55120",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "06131-694372 ",
    "phone2": "",
    "email": "Philipp.Schrimb@koebig.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1226,
    "firstName": "Andrzej Stanislaw",
    "secondName": "Bieniosek",
    "alias": "Andrzej Stanislaw Bieniosek Schreinerei Bieniosek (bieniosek@yahoo.de)",
    "name": "Schreinerei Bieniosek",
    "nip": "DE123456789",
    "street": "Henry-Ford II-Str. 7",
    "place": "Wülfrath",
    "zipCode": "42489",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "004915777622316",
    "phone2": "",
    "email": "bieniosek@yahoo.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1257,
    "firstName": "Wolfgang",
    "secondName": "Steinmetz",
    "alias": "Wolfgang Steinmetz Steinmetz Fenster & Türen (steinmetz@tuerenfenster.com)",
    "name": "Steinmetz Fenster & Türen",
    "nip": "DE 327069235",
    "street": "Karlstraße 18",
    "place": "Saarbrücken",
    "zipCode": "66125",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 68979669488",
    "phone2": "+49 1731973371",
    "email": "steinmetz@tuerenfenster.com",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1281,
    "firstName": "Manfred",
    "secondName": "Schön",
    "alias": "Manfred Schön Tischlerei Manfred Schön (info@schoen-tischlerei.de)",
    "name": "Tischlerei Manfred Schön",
    "nip": "DE 203848972",
    "street": "Am Sägewerk 3",
    "place": "Riesweiler",
    "zipCode": "55499",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "491713881025",
    "phone2": "",
    "email": "info@schoen-tischlerei.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1307,
    "firstName": "Theo",
    "secondName": "Testinger",
    "alias": "Theo Testinger  (peter@web.de)",
    "name": "",
    "nip": "",
    "street": "Musterstraße 3",
    "place": "Hier",
    "zipCode": "99999",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "096112333333",
    "phone2": "",
    "email": "peter@web.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1332,
    "firstName": "Max",
    "secondName": "Mustermann",
    "alias": "Max Mustermann  (em.herbig@gmail.com)",
    "name": "",
    "nip": "",
    "street": "Musterstrasse 111",
    "place": "Musterstsadt",
    "zipCode": "47589",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "015161450688",
    "phone2": "",
    "email": "em.herbig@gmail.com",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1352,
    "firstName": "Reinhold ",
    "secondName": "Steiger",
    "alias": "Reinhold  Steiger SCHIRLING-REGENSBURG-DE (steiger@schierling-tueren.de)",
    "name": "SCHIRLING-REGENSBURG-DE",
    "nip": "DE 133 702 437",
    "street": "Hofer Straße 35",
    "place": "Regensburg",
    "zipCode": "93057",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 941 7850617",
    "phone2": "",
    "email": "steiger@schierling-tueren.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1355,
    "firstName": "Danijela",
    "secondName": "Grgic",
    "alias": "Danijela Grgic VONOVIA (danijela.grgic@vonovia.de)",
    "name": "VONOVIA",
    "nip": "DE123456789",
    "street": "Universitätsstr. 133",
    "place": "Bochum",
    "zipCode": "44803",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "01622125120",
    "phone2": "",
    "email": "danijela.grgic@vonovia.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1392,
    "firstName": "Andreas",
    "secondName": "Messing",
    "alias": "Andreas Messing  (a.messing@schreinerei-messing.de)",
    "name": "",
    "nip": "",
    "street": "Viersener Str. 11",
    "place": "Grefrath",
    "zipCode": "47929",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "02158912120",
    "phone2": "",
    "email": "a.messing@schreinerei-messing.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1393,
    "firstName": "Thomas",
    "secondName": "Kleine",
    "alias": "Thomas Kleine Mightyspace (thomas@mightyspace.de)",
    "name": "Mightyspace",
    "nip": "DE123456789",
    "street": "Olper Hütte 5a",
    "place": "Olpe",
    "zipCode": "57462",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "020525028980",
    "phone2": "",
    "email": "thomas@mightyspace.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1394,
    "firstName": "Karlo",
    "secondName": "Obarek",
    "alias": "Karlo Obarek Fensterhaven (Mh@fensterhaven.de)",
    "name": "Fensterhaven",
    "nip": "DE288287001",
    "street": "Süderstraße 17",
    "place": "Bremerhaven",
    "zipCode": "27572",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "0471951444058",
    "phone2": "",
    "email": "Mh@fensterhaven.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1413,
    "firstName": "Sascha",
    "secondName": "Theiß",
    "alias": "Sascha Theiß  (info@schreiner-theiss.de)",
    "name": "",
    "nip": "",
    "street": "Carl-Borgward-Straße 2",
    "place": "Neuwied",
    "zipCode": "56566",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "02631/9549666",
    "phone2": "",
    "email": "info@schreiner-theiss.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1437,
    "firstName": "Hans-Joachim",
    "secondName": "Hoch",
    "alias": "Hans-Joachim Hoch  (info@hoch-bauelemente.de)",
    "name": "",
    "nip": "",
    "street": "Am Juffernpesch 3",
    "place": "Vettweiß",
    "zipCode": "52381",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "024242038559",
    "phone2": "",
    "email": "info@hoch-bauelemente.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1497,
    "firstName": "Christopher",
    "secondName": "Lücking",
    "alias": "Christopher Lücking Schreinerei Lücking (info@schreinereiluecking.de)",
    "name": "Schreinerei Lücking",
    "nip": "DE123456789",
    "street": "Weidenweg 38",
    "place": "Kalkar",
    "zipCode": "47546",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "004928249492907",
    "phone2": "",
    "email": "info@schreinereiluecking.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1529,
    "firstName": "Arben",
    "secondName": "Ajazi",
    "alias": "Arben Ajazi BFM Beckumer Fenstermontage (info@bfm-fenstermontage.de)",
    "name": "BFM Beckumer Fenstermontage",
    "nip": "DE 332234611",
    "street": "Ahlener Str. 37",
    "place": "Beckum",
    "zipCode": "59269",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "00491777990914",
    "phone2": "",
    "email": "info@bfm-fenstermontage.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1570,
    "firstName": "Kai",
    "secondName": "Vormann",
    "alias": "Kai Vormann Tischlerei (info@vormann.de)",
    "name": "Tischlerei",
    "nip": "DE170100560",
    "street": "Grundschötteler Straße 6",
    "place": "Hagen",
    "zipCode": "58135",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "00492331407707",
    "phone2": "",
    "email": "info@vormann.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1572,
    "firstName": "Alexander",
    "secondName": "Schuller",
    "alias": "Alexander Schuller Schuller GmbH (schullerfenster@t-online.de)",
    "name": "Schuller GmbH",
    "nip": "DE306228372",
    "street": "Viehmarkt 10b",
    "place": "Neumarkt",
    "zipCode": "92318",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "091812655540",
    "phone2": "",
    "email": "schullerfenster@t-online.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1611,
    "firstName": "Andy",
    "secondName": "Schmidt",
    "alias": "Andy Schmidt Schmidt Mölln (Andy.schm@gmx.de)",
    "name": "Schmidt Mölln",
    "nip": "DE166969817",
    "street": "Hans-Sachs-Straße 33",
    "place": "Mölln",
    "zipCode": "23979",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "01715158410",
    "phone2": "",
    "email": "Andy.schm@gmx.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1626,
    "firstName": "Sebastian",
    "secondName": "Fries",
    "alias": "Sebastian Fries Immobilien & Handwerkerservice Fries (info.fries@t-online.de)",
    "name": "Immobilien & Handwerkerservice Fries",
    "nip": "DE 361893450",
    "street": "Uerdinger Strasse 133",
    "place": "Moers",
    "zipCode": "47441",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "004928419811900",
    "phone2": "",
    "email": "info.fries@t-online.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1628,
    "firstName": "Daniel",
    "secondName": "Fuß",
    "alias": "Daniel Fuß Fenstertechnik + Bauelemente Daniel Fuß (info@bauelemente-fuss.de)",
    "name": "Fenstertechnik + Bauelemente Daniel Fuß",
    "nip": "DE227043888",
    "street": "Gerberstraße 4",
    "place": "Duisburg",
    "zipCode": "47249",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "00492039300633",
    "phone2": "",
    "email": "info@bauelemente-fuss.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1644,
    "firstName": "Markus ",
    "secondName": "Brause",
    "alias": "Markus  Brause Windoor Nord e.K. (m.brause@windoornord.de)",
    "name": "Windoor Nord e.K.",
    "nip": "DE359802933",
    "street": "Ahsener Straße 9",
    "place": "Thedinghausen",
    "zipCode": "27321",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "01772302941",
    "phone2": "",
    "email": "m.brause@windoornord.de",
    "notes": null,
    "rodo": 1
  },

  {
    "id": 1701,
    "firstName": "Thomas",
    "secondName": "Kleine",
    "alias": "Thomas Kleine MightySpace GmbH (thomas@mightyspace.de)",
    "name": "MightySpace GmbH",
    "nip": "DE339107594",
    "street": "Olper Hütte 5a",
    "place": "Olpe",
    "zipCode": "57462",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "004920525028980",
    "phone2": "",
    "email": "thomas@mightyspace.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1736,
    "firstName": "Oliver ",
    "secondName": "Wawroschek",
    "alias": "Oliver  Wawroschek Fenster-Türen Wawroschek (fenster.wawroschek@gmail.com)",
    "name": "Fenster-Türen Wawroschek",
    "nip": "DE340823448",
    "street": "Krumbacher Str. 45",
    "place": "Aletshausen",
    "zipCode": "86480",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 152 54664651",
    "phone2": "",
    "email": "fenster.wawroschek@gmail.com",
    "notes": "",
    "rodo": 1
  },
  {
    "id": 1737,
    "firstName": "Herr Roman ",
    "secondName": "ter Horst",
    "alias": "Herr Roman  ter Horst FENESTRA ter Horst GmbH (info@fenestra-online.de)",
    "name": "FENESTRA ter Horst GmbH",
    "nip": "DE174637217",
    "street": "Werner-Heisenberg-Str. 15",
    "place": "Emmerich am Rhein",
    "zipCode": "46446",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 2822 9575660",
    "phone2": "",
    "email": "info@fenestra-online.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1759,
    "firstName": "Klaus",
    "secondName": "Mühleis",
    "alias": "Klaus Mühleis Schreinerei Paul Stratmann GmbH Inh. Klaus Mühleis (schreinerei_stratmann@t-online.de)",
    "name": "Schreinerei Paul Stratmann GmbH Inh. Klaus Mühleis",
    "nip": "DE123456789",
    "street": "Wittener Str. 350",
    "place": "Castrop-Rauxel",
    "zipCode": "44577",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "00423056783",
    "phone2": "",
    "email": "schreinerei_stratmann@t-online.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1790,
    "firstName": "Frank",
    "secondName": "Maas",
    "alias": "Frank Maas  (bauelemente-maas@web.de)",
    "name": "",
    "nip": "",
    "street": "Dillinger Str. 30",
    "place": "Saarwellingen",
    "zipCode": "66793",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "06838384537",
    "phone2": "",
    "email": "bauelemente-maas@web.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1796,
    "firstName": "Johann",
    "secondName": "Göttfert",
    "alias": "Johann Göttfert  (info@goettfert-holz-art.de)",
    "name": "",
    "nip": "",
    "street": "Industriegelände 5",
    "place": "Wiehl",
    "zipCode": "51674",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 2262 729 00 11",
    "phone2": "",
    "email": "info@goettfert-holz-art.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1811,
    "firstName": "Christian",
    "secondName": "Smok",
    "alias": "Christian Smok Glas- und Fenstertechnik Smok UG (info@fenstertechnik-smok.de)",
    "name": "Glas- und Fenstertechnik Smok UG",
    "nip": "DE333074241",
    "street": "Schevenhofer Weg 66a",
    "place": "Wuppertal",
    "zipCode": "42111",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "02053504668",
    "phone2": "",
    "email": "info@fenstertechnik-smok.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1846,
    "firstName": "H....",
    "secondName": "Süer",
    "alias": "H.... Süer Fenster & Bauelemente Sueer GmbH (hs@fenstersueer.de)",
    "name": "Fenster & Bauelemente Sueer GmbH",
    "nip": "DE123456789",
    "street": "Sandstr. 96",
    "place": "Mülheim an der Ruhr",
    "zipCode": "45473",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "00491739990347",
    "phone2": "",
    "email": "hs@fenstersueer.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1848,
    "firstName": "Udo",
    "secondName": "Kleinjans",
    "alias": "Udo Kleinjans Kleinjans Bau- und Möbelschreinerei (info@schreinerei-kleinjans.de)",
    "name": "Kleinjans Bau- und Möbelschreinerei",
    "nip": "DE123456789",
    "street": "Döppchesstr. 28",
    "place": "Linnich",
    "zipCode": "52441",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "0049246274016",
    "phone2": "",
    "email": "info@schreinerei-kleinjans.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1856,
    "firstName": "Domenique",
    "secondName": "Burt",
    "alias": "Domenique Burt Burt Innenausbau (burt.innenausbau@gmail.com)",
    "name": "Burt Innenausbau",
    "nip": "DE123456789",
    "street": "Verdistr. 25",
    "place": "Hilden",
    "zipCode": "40724",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "00491749404545",
    "phone2": "",
    "email": "burt.innenausbau@gmail.com",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1862,
    "firstName": "Stephen",
    "secondName": "Tolle ",
    "alias": "Stephen Tolle  Schattenzauber (stephen.tolle@icloud.com)",
    "name": "Schattenzauber",
    "nip": "DE123456789",
    "street": "Westerdeich 5",
    "place": "Bremen",
    "zipCode": "28197",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "017681259914",
    "phone2": "",
    "email": "stephen.tolle@icloud.com",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1872,
    "firstName": "Siviryos",
    "secondName": "Demir",
    "alias": "Siviryos Demir RUB2-AUGSBURG-DE (rubelemente@hotmail.de)",
    "name": "RUB2-AUGSBURG-DE",
    "nip": "DE304484166",
    "street": "Ebnerstraße 10",
    "place": "Augsburg",
    "zipCode": "86154",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "017647732727",
    "phone2": "",
    "email": "rubelemente@hotmail.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1876,
    "firstName": "Josef",
    "secondName": "Mühldorfer",
    "alias": "Josef Mühldorfer MUHLDORFER-GLASEREI-DE (info@glas-muehldorfer.de)",
    "name": "MUHLDORFER-GLASEREI-DE",
    "nip": "DE 133959361",
    "street": "Hussenstraße 16",
    "place": "Rötz",
    "zipCode": "92444",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "+49 9976 1569",
    "phone2": "",
    "email": "info@glas-muehldorfer.de",
    "notes": null,
    "rodo": 1
  },
  {
    "id": 1892,
    "firstName": "Stephan",
    "secondName": "Tolle",
    "alias": "Stephan Tolle Schattentraum (stephen.tolle@icloud.com)",
    "name": "Schattentraum",
    "nip": "DE123456789",
    "street": "Westerdeich 5",
    "place": "Bremen",
    "zipCode": "28197",
    "country": "Deutschland",
    "idCountry": 2,
    "phone1": "017681259914",
    "phone2": "",
    "email": "stephen.tolle@icloud.com",
    "notes": null,
    "rodo": 1
  }]
        //console.log(thisVue.customers);
      });
    },
    showAddNewCustomer() {
      this.displayNewCustomer = true;
    },
    closeAddNewCustomer() {
      this.displayNewCustomer = false;
    },
    newCustomerOpened() {
      this.$refs.customerEdit.addNewCustomer();
    },
    toggleCustomerValid() {
      this.customerInValid = false;
    },
    customerAdded(customer) {
      this.displayNewCustomer = false;
      this.customers.push(customer);
      this.offer.idCustomer = customer.id;
    },
    exit() {
      this.$router.push(`/`);
    },
    closePage() {
      if (this.offer.name !== "") {
        this.displayDialogCancel = true;
      } else {
        this.$router.push(`/`);
      }
    },
    checkValid() {
      return this.offer.name !== "";
    },
  },
  components: {
    ActionsMenu,
    CustomerEdit,
    VButton,
  },
};
</script>
