<template>
  <div class="row my-2 p-0 mx-0" v-if="variant !== undefined">
    <div class="col-md-1" style="text-align: right">
      <span v-on:click="showItems = !showItems" class="red-circle">
        <div style="display: flex; width: 100%; justify-content: center">
          <div style="margin-top: -0.3rem">
            {{ showItems ? "-" : "+" }}
          </div>
        </div>
      </span>
    </div>
    <div class="col-md-11">
      <div class="row offer__content">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-10 pl-0">
              <div class="col-md-3 py-2">
                <label class="offer--input-label" for="inputtext1">{{
                  $t("strVariantName")
                }}</label>
                <InputText
                  id="inputtext1"
                  :disabled="isEditing === false"
                  class="offer--input"
                  type="text"
                  v-model="variant.nameVariant"
                />
              </div>
              <div class="col-md-3 py-2">
                <label class="offer--input-label">{{
                  $t("strLastUpdate")
                }}</label>
                <InputText
                  id="inputtext1"
                  disabled="true"
                  class="offer--input"
                  type="text"
                  :value="variant.lastUpdate | formatDate"
                />
              </div>
              <div class="col-md-3 py-2">
                <label class="offer--input-label" for="weldKind">{{
                  $t("strWeldKind")
                }}</label>
                <Dropdown
                  id="weldKind"
                  v-model="variant.typeOfWelding"
                  :options="weldList"
                  optionLabel="weldName"
                  optionValue="id"
                  :disabled="isEditing === false"
                  class="offer--input"
                />
                <!-- <InputText
                  id="inputtext1"
                  :disabled="isEditing === false"
                  class="offer--input"
                  type="text"
                  v-model="variant.idVariant"
                /> -->
              </div>
              <div class="col-md-12">
                <label class="offer--input-label">{{
                  $t("strTechnologyColor")
                }}</label>
                <div
                  style="
                    width: 100%;
                    height: 100px;
                    background: #fafafa;
                    border: 1px solid #f3f3f3;
                    font-size: 14px;
                    font-weight: 700;
                    color: #495057;
                  "
                >
                  {{ variant.techColor }}
                </div>
              </div>
            </div>
            <div class="col-md-2 p-0">
              <div v-if="Number(variant.sentToRealisation) === -1 && canEdit">
                <div class="responsiveBtns">
                  <div>
                    <VButton
                      class="btn-outline-light w-100"
                      @click="openConfigurator(variant.idVariant)"
                      icon="+"
                      strKey="strProduct"
                      roleKey="variantAddProduct"
                    />
                  </div>
                  <div>
                    <VButton
                      class="btn-outline-light w-100"
                      @click="displayExtraProducts = !displayExtraProducts"
                      icon="+"
                      strKey="strAccessoriesAdd"
                      roleKey="variantAccessoriesAdd"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <VButton
                  class="btn-outline-light w-100"
                  @click="displayEmptyItems = !displayEmptyItems"
                  strKey="strEmptyItem"
                  roleKey="variantEmptyItem"
                />
              </div>
              <div class="mt-2">
                <VButton
                  class="btn-green w-100"
                  :class="{ isDisabled: variant.btnState !== 1 }"
                  :disabled="variant.btnState !== 1"
                  @click="exportVariant"
                  v-if="variant.btnState !== -1"
                  :strKey="variant.btnName"
                  style="white-space: normal;"
                />
              </div>
              <div
                class="mt-2"
                style="display: none;"
                v-if="
                  !(
                    !(Number(variant.sentToRealisation) === -1) &&
                    kindRole === 1 &&
                    idOrderStatus !== 7
                  )
                "
              >
                <VButton
                  class="btn-green w-100"
                  :class="{
                    isDisabled:
                      (!canEdit && idOrderStatus !== 7 && kindRole === 1) ||
                      (idOrderStatus === 7 &&
                        kindRole === 1 &&
                        Number(variant.sentToRealisation) === -1),
                  }"
                  :disabled="
                    (!canEdit && idOrderStatus !== 7 && kindRole === 1) ||
                      (idOrderStatus === 7 &&
                        kindRole === 1 &&
                        Number(variant.sentToRealisation) === -1)
                  "
                  @click="exportVariant"
                  v-if="
                    (positions.length > 0 && kindRole === 1) ||
                      (Number(variant.dealerPrice) > 0 && kindRole === 1) ||
                      (Number(variant.clientFullPrice) > 0 && kindRole === 1) ||
                      (idOrderStatus === 7 && kindRole === 1)
                  "
                  strKey="strSendToRealization"
                  roleKey="variantSendToRealization"
                />
                <VButton
                  class="btn-green w-100"
                  :class="{ isDisabled: !canEdit }"
                  :disabled="!canEdit"
                  @click="exportVariant"
                  v-if="
                    positions.length > 0 &&
                      Number(variant.dealerPrice) &&
                      Number(variant.clientFullPrice) > 0 &&
                      Number(variant.sentToRealisation) === -1 &&
                      kindRole === 0
                  "
                  strKey="strSendToApproval"
                  roleKey="variantSendToApproval"
                />
              </div>
              <VButton
                class="btn-green w-100"
                style="cursor: default;display:none;"
                v-if="
                  (!(Number(variant.sentToRealisation) === -1) &&
                    kindRole === 1 &&
                    idOrderStatus !== 7) ||
                    (!(Number(variant.sentToRealisation) === -1) &&
                      kindRole === 0 &&
                      idOrderStatus !== 7)
                "
                strKey="strSentForExecution"
                roleKey="variantSentForExecution"
              />
              <VButton
                class="btn-green w-100"
                style="cursor: default;display:none"
                v-if="
                  !(Number(variant.sentToRealisation) === -1) &&
                    kindRole === 0 &&
                    idOrderStatus === 7
                "
                strKey="strSentForApproval"
                roleKey="variantSentForApproval"
              />
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div style="position: absolute; right: 10px; z-index: 99">
            <span v-if="Number(variant.value_k) !== 0">
            <VRole roleKey="variantDuplicate">
              <img
                src="@/assets/icons/vetrex-ikony/copy_outlined_32.svg"
                v-if="
                  (Number(variant.sentToRealisation) === -1) & canEdit "
                @click="duplicateVariant"
                alt=""
                style="cursor: pointer; width: 22px; margin-right: 1.75rem"
              />
            </VRole>
          </span>
          <span v-if="Number(variant.value_d) !== 0">
            <VRole roleKey="variantPrint">
              <img
                src="@/assets/icons/vetrex-ikony/print_outlined_32.svg"
                alt=""
                @click="showPrint"
                style="cursor: pointer; width: 22px; margin-right: 1.75rem"
              />
            </VRole>
            </span>
            <span v-if="Number(variant.value_u) !== 0">
            <VRole roleKey="variantDelete">
              <img
                src="@/assets/icons/vetrex-ikony/trash_outlined_32.svg"
                v-if="
                  (isEditing === false &&
                    Number(variant.sentToRealisation) === -1) & canEdit
                "
                @click="displayDeleteDialog = true"
                alt=""
                style="cursor: pointer; width: 22px; margin-right: 1.75rem"
              />
            </VRole>
          </span>
          <span v-if="Number(variant.value_e) !== 0">
            <VRole roleKey="variantEdit">
              <img
                src="@/assets/icons/edycja.svg"
                v-if="
                  (isEditing === false) &&
                    (Number(variant.sentToRealisation) === -1) &&
                    canEdit
                "
                alt=""
                style="cursor: pointer; width: 22px"
                @click="showEdit"
              />
            </VRole>
            </span>
            <img
              v-if="isEditing === true"
              src="@/assets/icons/vetrex-ikony/save_outlined_32.svg"
              alt=""
              style="cursor: pointer; width: 22px"
              @click="saveVariant"
            />
            <img
              src="@/assets/icons/arrow.svg"
              alt=""
              v-on:click="show = !show"
              style="cursor: pointer; width: 12px; margin-left: 1.5rem"
              v-bind:style="[
                show
                  ? { transform: 'rotate(-90deg)' }
                  : { transform: 'rotate(90deg)' },
              ]"
            />
            <!-- <div class="variant-price">{{variant.clientFullPrice}} PLN</div> -->
            <div
              style="
                position: absolute;
                z-index: 99;
                color: #495057;
                min-width: 280px;
                right: -1.25rem;
                top: 140px
              "
            >
              <VRole roleKey="variantPositionsQuantity">
                <div>
                  {{ $t("strAmountPositions") }}
                  <span style="font-size: 16px">
                    {{ positionsQuantity }}
                  </span>
                </div>
              </VRole>
              <VRole roleKey="variantFinalPriceDealer">
                <div>
                  {{ $t("strfinalPriceDealer") }}:
                  <span style="font-size: 16px"
                    >{{ variant.dealerPrice }} {{ currencyName }}</span
                  >
                </div>
              </VRole>
              <VRole roleKey="variantFinalPriceClient">
                <div>
                  {{ $t("strFinalPriceClient") }}:
                  <span style="font-size: 16px"
                    >{{ variant.clientFullPrice }} {{ currencyName }}</span
                  >
                </div>
              </VRole>
            </div>
          </div>
        </div>
        <div class="col-md-11" v-show="show">
          <div class="row">
            <div
              class="col-xs-12"
              style="
                margin-left: 15px;
                border-top: 1px solid rgba(0, 0, 0, 0.2);
                margin-top: 2.75rem;
                margin-bottom: 2.5rem;
              "
            ></div>
            <VRole roleKey="variantUnloading">
              <div class="col-md-3 py-2">
                <label class="offer--input-label" for="unloading">{{
                  $t("strUnloading")
                }}</label>
                <Dropdown
                  id="unloading"
                  v-model="variant.unloading"
                  :options="unloadingList"
                  optionLabel="unloadingName"
                  optionValue="id"
                  disabled
                  class="offer--input"
                />
                <!-- <InputText
                  id="inputtext1"
                  :disabled="isEditing === false"
                  class="offer--input"
                  type="text"
                  v-model="variant.unloading"
                /> -->
              </div>
            </VRole>
            <VRole roleKey="variantDiscountSkonto">
              <div class="col-md-3 py-2">
                <label class="offer--input-label" for="skontoDiscount">{{
                  $t("strDiscountSkonto")
                }}</label>
                <InputNumber
                  id="skontoDiscount"
                  :disabled="isEditing === false"
                  style="width: 100%"
                  type="text"
                  v-model="variant.skontoDiscount"
                  :minFractionDigits="0"
                  :maxFracionDigits="0"
                  :min="0"
                  :max="100"
                  :showButtons="true"
                />
              </div>
            </VRole>
            <VRole roleKey="variantDiscountExtra">
              <div class="col-md-3 py-2">
                <label class="offer--input-label" for="extraDiscount">{{
                  $t("strDiscountExtra")
                }}</label>
                <InputNumber
                  id="extraDiscount"
                  :disabled="isEditing === false"
                  style="width: 100%"
                  type="text"
                  v-model="variant.extraDiscount"
                  :minFractionDigits="0"
                  :maxFracionDigits="0"
                  :min="0"
                  :max="100"
                  :showButtons="true"
                />
              </div>
            </VRole>
            <VRole roleKey="variantDiscountAssembly">
              <div class="col-md-3 py-2">
                <label class="offer--input-label" for="extraAssemblyDiscount">{{
                  $t("strDiscountAssembly")
                }}</label>
                <InputNumber
                  id="skotnoDiscount"
                  :disabled="isEditing === false"
                  style="width: 100%"
                  type="text"
                  v-model="variant.extraAssemblyDiscount"
                  :minFractionDigits="0"
                  :maxFracionDigits="0"
                  :min="0"
                  :max="100"
                  :showButtons="true"
                />
              </div>
            </VRole>
            <VRole roleKey="variantCurrency">
              <div class="col-md-3 py-2">
                <label class="offer--input-label" for="currency">{{
                  $t("strCurrency")
                }}</label>
                <Dropdown
                  id="currency"
                  v-model="idCurrency"
                  :options="currList"
                  optionLabel="currency"
                  optionValue="id"
                  :disabled="isEditing === false"
                  class="offer--input"
                />
                <!-- <InputText
                  id="inputtext1"
                  :disabled="isEditing === false"
                  class="offer--input"
                  type="text"
                  v-model="variant.currency"
                /> -->
              </div>
            </VRole>
            <div class="col-md-3" style="padding: 0">
              <VRole roleKey="variantTax">
                <div class="col-md-6 py-2">
                  <label class="offer--input-label" for="idVat">{{
                    $t("strTax")
                  }}</label>
                  <Dropdown
                    id="idVat"
                    v-model="variant.idVat"
                    :options="taxList"
                    optionLabel="taxRate"
                    optionValue="id"
                    :disabled="isEditing === false"
                    class="offer--input"
                  />
                  <!-- <InputText
                  id="inputtext1"
                  :disabled="isEditing === false"
                  class="offer--input"
                  type="text"
                  v-model="variant.sellVat"
                /> -->
                </div>
              </VRole>
              <VRole roleKey="variantTaxPurchase">
                <div class="col-md-6 py-2">
                  <label class="offer--input-label" for="purchaseVat">{{
                    $t("strTaxPurchase")
                  }}</label>
                  <!-- <Dropdown id="purchaseVat" v-model="variant.purchaseVat" :options="taxList" optionLabel="taxRate" optionValue="id" :disabled="isEditing === false"
                    class="offer--input"/> -->
                  <InputText
                    id="purchaseVat"
                    disabled
                    class="offer--input"
                    type="text"
                    v-model="variant.purchaseVat"
                  />
                </div>
              </VRole>
            </div>
            <!-- <div class="col-md-3 py-2">
              <label class="offer--input-label" for="purchaseCurrency">{{
                $t("strPurchaseCurrency")
              }}</label>
              <InputNumber
                id="purchaseCurrency"
                disabled
                v-model="variant.purchaseCurrency"
                mode="decimal"
                style="width: 100%"
                :minFractionDigits="2"
                :maxFracionDigits="4"
              />
            </div> -->
            <!-- <div class="col-md-3 py-2">
              <label class="offer--input-label" for="sellCurrency">{{
                $t("strSellCurrency")
              }}</label>
              <br />
              <InputNumber
                id="sellCurrency"
                :disabled="isEditing === false"
                v-model="variant.sellCurrency"
                style="width: 100%"
                mode="decimal"
                :minFractionDigits="2"
                :maxFracionDigits="4"
              />
            </div> -->
          </div>
          <div class="row">
            <VRole roleKey="variantNotes">
              <div class="col-md-6">
                <label class="offer--input-label" for="inputtext1">{{
                  $t("strNotes")
                }}</label>
                <Textarea
                  :autoResize="true"
                  rows="5"
                  cols="30"
                  class="offer--input"
                  v-model="variant.notes"
                  :disabled="isEditing === false"
                />
              </div>
            </VRole>
            <VRole roleKey="variantNotesCustomer">
              <div class="col-md-6">
                <label class="offer--input-label" for="inputtext1">{{
                  $t("strNotesCustomer")
                }}</label>
                <Textarea
                  :autoResize="true"
                  rows="5"
                  cols="30"
                  class="offer--input"
                  v-model="variant.customerNotes"
                  :disabled="isEditing === false"
                />
              </div>
            </VRole>
          </div>
        </div>
        <div
          class="col-md-12 offer-footer toRight d-flex mt-2"
          v-if="isEditing"
        >
          <VButton
            class="btn-outline-red"
            @click="closeEdit"
            strKey="strCancel"
          />
        </div>
      </div>
    </div>
    <div class="col-md-12 p-0 items-container">
      <VariantItem
        v-for="item in positions"
        :key="item.idPosition"
        :orderId="variant.idOrder"
        :item="item"
        :canEdit="Number(variant.sentToRealisation) === -1 && canEdit"
        :currencyName="currencyName"
        v-bind:style="[showItems ? { display: 'block' } : { display: 'none' }]"
      />
    </div>
    <Dialog
      header="Wybierz kategorię produktu"
      :visible.sync="displayNewProduct"
      :style="{ width: '90%', height: '90%' }"
      :modal="true"
      @show="newProductShowed"
    >
      <ProductHierarchySelect
        @selectedItem="selectedItemClicked"
        :level="1"
        v-if="
          newProductTreeItem === undefined ||
            newProductTreeItem === null ||
            newProductTreeItem.id === undefined
        "
      />
      <div class="row">
        <div class="col-md-12">
          <h4>Wybrałeś: {{ newProductTreeItem.name }}</h4>
          <ProductEdit ref="productEditView" />
        </div>
      </div>
    </Dialog>
    <Dialog
      :header="$t('strPrintHeader')"
      :visible.sync="displayPrint"
      :style="{ width: '90%', height: '90%' }"
      :modal="true"
      @show="printShowed"
    >
      <div
        class="row"
        style="
          position: absolute;
          top: 41px;
          width: 100%;
          z-index: 1000;
          padding: 0;
          margin: 0;
          background: #fff;
          border-bottom: 1px solid #e9ecef;
          width: 95%;
        "
      >
        <div class="col-md-10">
          <div class="col-md-3 py-2">
            <label class="offer--input-label" for="printType">{{
              $t("strSelectedPrintType")
            }}</label>
            <Dropdown
              id="printType"
              class="offer--input"
              v-model="selectedPrintType"
              :options="printTypes"
              placeholder=""
              optionLabel="offerName"
              optionValue="type"
              :disabled="disablePrint"
            >
            </Dropdown>
          </div>

          <div class="col-md-5 py-2">
            <VButton
              class="btn-red"
              @click="loadPrint"
              style="margin-top: 2.5rem"
              strKey="strShowOffer"
            />
            <VButton
              class="btn-outline-red"
              v-if="printLoaded"
              @click="printCurrentDocument"
              style="margin-top: 2.5rem; margin-left: 1rem"
              strKey="btnPrint"
            />
            <VButton
              class="btn-outline-red"
              v-if="printLoaded"
              @click="download"
              style="margin-top: 2.5rem; margin-left: 1rem"
              strKey="strDownload"
            />
          </div>
        </div>
      </div>
      <div class="row" style="min-height: 100px; margin-top: 100px">
        <div class="col-md-12">
          <!-- <h4>Wybrałeś: </h4> -->
          <!-- <pdf ref="printPreview" :src="currentPdfUrl" v-show="currentPdfUrl !== ''"></pdf> -->
          <div
            v-if="onLoadingPdf"
            style="text-align: center; font-size: 1.5rem; margin-top: 2rem"
          >
            {{ $t("strPleaseWait") }}
          </div>
          <div v-if="printLoaded">
            <vue-pdf-embed ref="printPreview" :source="pdfUrl" />
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDeleteDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="
          display: flex;
          padding: 5rem;
          justify-content: center;
          font-size: 20px;
        "
      >
        {{ $t("qnVariantDelete") }}
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 2rem 1rem 2rem;
        "
      >
        <VButton
          class="btn-red"
          @click="displayDeleteDialog = false"
          strKey="strCancel"
        />
        <VButton
          class="btn-outline-red"
          @click="deleteVariant"
          strKey="strDelete"
        />
      </div>
    </Dialog>
    <ExtraProducts
      v-show="displayExtraProducts"
      @hideExtraProducts="hideExtraProducts"
      @accessoryAdded="accessoryAdded"
      @accessoryDeleted="accessoryDeleted"
      :technologyId="49"
      :accessories="accessories"
      :variantId="variant.idVariant"
    />
    <EmptyPosition
      v-show="displayEmptyItems"
      @hideEmptyPositions="hideEmptyPositions"
      @loadVariantPositions="loadVariantPositions"
      :variantId="variant.idVariant"
    />
  </div>
</template>

<script>
import { orderService } from "../../services/order.service.js";
import { productService } from "../../services/product.service.js";
import { dictionariesService } from "../../services/dictionaries.service.js";
import VariantItem from "@/components/offers/VariantItem";
import ProductHierarchySelect from "@/components/product/ProductHierarchySelect";
import ProductEdit from "@/components/product/ProductEdit";
import ExtraProducts from "@/components/offers/ExtraProducts";
import EmptyPosition from "@/components/offers/EmptyPosition";
import VButton from "../global/VButton.vue";
import VRole from "../global/VRole.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { mapState } from "vuex";

export default {
  name: "Variant",
  props: {
    variantOrg: {},
    canEdit: Boolean,
    idOrderStatus: Number,
  },
  data() {
    return {
      response: {},
      positions: [],
      displayNewProduct: false,
      displayPrint: false,
      show: false,
      showItems: false,
      newProductTreeItem: {},
      productToEdit: {},
      newProductView: {},
      displayExtraProducts: false,
      displayEmptyItems: false,
      accessories: [],
      isEditing: false,
      unloadingList: [],
      currList: [],
      weldList: [],
      taxList: [],
      displayDeleteDialog: false,
      selectedPrintType: -1,
      src: undefined,
      numPages: undefined,
      printLoaded: false,
      idCurrency: 0,
      variant: {},
      currencyName: "",
      printTypes: [],
      onLoadingPdf: false,
      disablePrint: false,
      positionsQuantity: 0,
      pdfUrl: "",
      fileName: "",
    };
  },
  computed: {
    ...mapState(["kindRole"]),
  },
  mounted: function mounted() {
    this.idCurrency = this.variant.idCurrency;
  },
  methods: {
    openConfigurator(id) {
      localStorage.setItem("idProduct", this.variant.idOrder);
      localStorage.setItem("idVariant", id);
      this.$router.push(`/configurator/${id},${this.variant.idOrder}`);
    },
    showPrint() {
      this.selectedPrintType = -1;
      this.displayPrint = true;
      //this.loadPrint(this.selectedPrintType);
    },
    printCurrentDocument() {
      const pages = this.$refs.printPreview.pageCount;
      this.$refs.printPreview.print(300, pages);
    },
    download() {
      fetch(this.pdfUrl, {
        method: "GET",
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = this.fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    },
    printShowed() {
      this.showPrint();
    },
    getPrintTypes() {
      const thisVue = this;
      orderService.getPrintTypes().then((response) => {
        if (response.isSuccess) {
          // let disabeldSelect = [{
          //   "id": -1,
          //   "type": -1,
          //   "offerName": "Wybierz",
          //   "disabled": true
          // }];
          let types = [...response.data];
          thisVue.printTypes = types;
        } else
          thisVue.$toast.add({
            severity: "error",
            summary: response.statusMessage,
            detail: "",
          });
      });
    },
    loadVariantPositions: function() {
      if (this.variant === undefined) {
        this.positions = [];
        return;
      }
      orderService
        .getVariantPositions(this.variant.idVariant)
        .then((response) => {
          this.positions = response.data || [];
          this.positionsQuantity = 0;
          response.data.forEach((e) => {
            this.positionsQuantity += e.quantity;
          });
          // console.log('positions:');
          // console.log(this.positions);
        });
    },
    showEdit() {
      this.isEditing = true;
    },
    closeEdit() {
      this.isEditing = false;
      this.variant = JSON.parse(JSON.stringify(this.variantOrg));
      let c = this.currList.find((e) => e.id === this.variant.idCurrency);
      this.currencyName = c.currency;
      this.idCurrency = this.variant.idCurrency;
    },
    saveVariant() {
      if (this.variant === undefined) return;

      const recalcRequired =
        JSON.stringify(
          (({
            skontoDiscount,
            sellCurrency,
            purchaseCurrency,
            idVat,
            purchaseVat,
            extraDiscount,
            idCurrency,
            extraAssemblyDiscount
          }) => ({
            skontoDiscount,
            sellCurrency,
            purchaseCurrency,
            idVat,
            purchaseVat,
            extraDiscount,
            idCurrency,
            extraAssemblyDiscount
          }))(this.variant)
        ) !=
        JSON.stringify(
          (({
            skontoDiscount,
            sellCurrency,
            purchaseCurrency,
            idVat,
            purchaseVat,
            extraDiscount,
            idCurrency,
            extraAssemblyDiscount
          }) => ({
            skontoDiscount,
            sellCurrency,
            purchaseCurrency,
            idVat,
            purchaseVat,
            extraDiscount,
            idCurrency,
            extraAssemblyDiscount
          }))(this.variantOrg)
        );

      const thisVue = this;
      orderService.updateVariant(this.variant).then((response) => {
        if (response.isSuccess) {
          if (recalcRequired) {
            orderService
              .recalcVariant(thisVue.variant.idVariant, thisVue.variant.idOrder)
              .then((responseRecalc) => {
                this.$parent.variantUpdated(responseRecalc.data[0]);
                let c = thisVue.currList.find(
                  (e) => e.id === responseRecalc.data[0].idCurrency
                );
                thisVue.currencyName = c.currency;
              });
          } else {
            this.$parent.variantUpdated(response.data[0]);
            let c = thisVue.currList.find(
              (e) => e.id === response.data[0].idCurrency
            );
            thisVue.currencyName = c.currency;
          }

          thisVue.isEditing = false;
        } else {
          thisVue.$toast.add({
            severity: "error",
            summary: thisVue.$t("strOffer"),
            detail: response.statusMessage,
          });
        }
      });
    },
    recalcVariantt() {
      const thisVue = this;
      orderService
        .recalcVariant(thisVue.variant.idVariant, thisVue.variant.idOrder)
        .then((responseRecalc) => {
          this.$parent.variantUpdated(responseRecalc.data[0]);
          let c = thisVue.currList.find(
            (e) => e.id === responseRecalc.data[0].idCurrency
          );
          thisVue.currencyName = c.currency;
        });
    },
    accessoryAdded(item) {
      if (this.accessories === null) this.accessories = [];
      //console.log(item);
      this.accessories.push(item);
    },
    accessoryDeleted(data) {
      const ind = this.accessories.findIndex((i) => i.id === data.id);
      if (ind >= 0) {
        this.accessories.splice(ind, 1);
      } else {
        console.log(data);
      }
    },
    loadVariantAccessories: function() {
      if (this.variant === undefined) {
        this.accessories = [];
        return;
      }
      orderService
        .getVariantAccessories(this.variant.idVariant)
        .then((response) => {
          this.accessories = response.data || [];
        });
    },
    newProductShowed() {
      this.newProductView = this.$refs.productEditView;
      this.newProductView.clearProduct();
    },
    addNewProduct: function() {
      this.newProductTreeItem = {};
      this.displayNewProduct = true;
    },
    selectedItemClicked: function(item) {
      if (item !== undefined && item !== null && item.childrenCount === 0)
        this.newProductTreeItem = item;
      const thisVue = this;
      productService.getInitialProduct(item.id).then((response) => {
        thisVue.productToEdit = response;
        thisVue.newProductView.loadNewProduct(response);
      });
    },
    hideExtraProducts: function() {
      this.displayExtraProducts = false;
    },
    duplicateVariant: function() {
      orderService.duplicateVariant(this.variant.idVariant).then((response) => {
        if (response.isSuccess) {
          this.$toast.add({
            severity: "success",
            summary: this.$t("strOffer"),
            detail: this.$t("strVariantCopied"),
            life: 3000,
          });
          this.$parent.variantAdded(response.data);
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: response.statusMessage,
          });
        }
      });
    },
    deleteVariant() {
      let variantId = this.variant.idVariant;
      orderService.deleteVariant(this.variant.idVariant).then((response) => {
        if (response.isSuccess) {
          this.$toast.add({
            severity: "success",
            summary: this.$t("strOffer"),
            detail: this.$t("strVariantDeleted"),
            life: 3000,
          });
          this.$parent.variantDeleted(variantId);
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: response.statusMessage,
          });
        }
      });
      this.displayDeleteDialog = false;
      this.$parent.variantDeleted(variantId);
    },
    productAdded(position) {
      this.positions.push(position);
      this.variant.dealerPrice =
        Number(this.variant.dealerPrice) + Number(position.finalPriceDealer);
      this.variant.clientFullPrice =
        Number(this.variant.clientFullPrice) +
        Number(position.finalPriceClient);
      this.positionsQuantity = 0;
      this.positions.forEach((e) => {
        this.positionsQuantity += e.quantity;
      });
    },
    productDeleted(idPosition) {
      let index = this.positions.findIndex((i) => i.idPosition === idPosition);
      if (index > -1) {
        this.positions.splice(index, 1);
      }
      this.$parent.refreshVariants();
      this.recalcVariantt();
    },
    productUpdated() {
      this.$parent.refreshVariants();
      this.recalcVariantt();
    },
    exportVariant() {
      const thisVue = this;
      let status = -1;
      if (this.idOrderStatus === 7) status = 11;
      orderService
        .exportVariant(this.variant.idVariant, status)
        .then((response) => {
          if (response.isSuccess) {
            thisVue.$parent.loadOrder();
          }
        });
    },
    loadDictionaries() {
      const thisVue = this;
      dictionariesService.getUnloadingList().then((response) => {
        thisVue.unloadingList = response.data;
      });
      dictionariesService.getWeldList().then((response) => {
        thisVue.weldList = response.data;
      });
      dictionariesService.getCurrList().then((response) => {
        thisVue.currList = response.data;
        let c = thisVue.currList.find(
          (e) => e.id === thisVue.variant.idCurrency
        );
        thisVue.currencyName = c.currency;
      });
      dictionariesService.getTaxes().then((response) => {
        thisVue.taxList = response.data;
      });
    },
    loadPrint() {
      const printType = this.selectedPrintType;
      const thisVue = this;
      thisVue.onLoadingPdf = true;
      thisVue.printLoaded = false;
      thisVue.disablePrint = true;
      if (printType < 0) {
        thisVue.src = "";
        thisVue.printLoaded = false;
        thisVue.onLoadingPdf = false;
        thisVue.disablePrint = false;
        return;
      }
      orderService
        .getOffer(thisVue.variant.idVariant, printType)
        .then((response) => {
          const pdfUrl =
            response.data[0].pdfURL +
            `?t=${Date.now().toString(36) +
              Math.random()
                .toString(36)
                .substr(2)}`;
          this.fileName = /[^/]*$/.exec(response.data[0].pdfURL)[0];
          this.pdfUrl = pdfUrl;
          this.onLoadingPdf = false;
          this.printLoaded = true;
        });
    },
    hideEmptyPositions: function() {
      this.displayEmptyItems = false;
    },
  },
  watch: {
    $props: {
      handler() {
        this.variant = JSON.parse(JSON.stringify(this.variantOrg));
        this.loadVariantPositions();
        this.loadVariantAccessories();
        this.loadDictionaries();
        this.getPrintTypes();
      },
      deep: true,
      immediate: true,
    },
    displayPrint() {
      this.printLoaded = false;
      this.onLoadingPdf = false;
      this.disablePrint = false;
      this.selectedPrintType = -1;
    },
    // selectedPrintType: function () {
    //   // this.loadPrint(newValue);
    //   // this.printLoaded = false;
    // },
    idCurrency: function(newValue) {
      this.idCurrency = newValue;
      this.variant.idCurrency = newValue;
      let cur = this.currList.find((e) => e.id === newValue);
      if (cur) {
        this.variant.purchaseCurrency = cur.rateExchange;
        this.variant.sellCurrency = cur.rateExchange;
      }
    },
  },
  components: {
    VariantItem,
    ProductHierarchySelect,
    ProductEdit,
    ExtraProducts,
    EmptyPosition,
    VuePdfEmbed,
    VButton,
    VRole,
  },
};
</script>

<style scoped>
.isDisabled {
  background: #777777;
  color: #fff;
}
</style>
